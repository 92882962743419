import React from 'react';
import { DeveloperProgram } from '../../components/DeveloperProgram/DeveloperProgram';
import Layout from '../../components/layout';
import { ThinkZambiaProvider } from '../../context';

export default function DeveloperPage() {
  return (
    <ThinkZambiaProvider>
      <Layout>
        <div style={{
          backgroundColor: '#f5f5f5',
        }}
        >
          <DeveloperProgram />
        </div>
      </Layout>
    </ThinkZambiaProvider>
  );
}
